/* eslint-disable no-undef */

export const titleUrl = 'Provider Portal | Eden Care';

export const baseURLv2 = 'https://service.edencaremedical.com/prod/api/v2';

const ProdURL = 'https://service.edencaremedical.com/prod/api/v1';
const QA_URL = 'https://service.edencaremedical.com/demo/api/v1';
const DEMO_URL = 'https://service.edencaremedical.com/demo/api/v1';
const UAT_URL = 'https://service.edencaremedical.com/uat/api/v1';

export const baseUrl =
    process.env.REACT_APP_VERCEL_ENV === 'production'
        ? ProdURL
        : process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'demo'
            ? DEMO_URL
            : process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'UAT'
?UAT_URL:QA_URL
