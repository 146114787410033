import Routing from './router';
import React, { useEffect, useState } from 'react';
import { fetchToken, onMessageListener } from './firebase';
import axios from 'axios';
import { baseUrl } from './utils';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [deviceToken, setDeviceToken] = useState();
  const navigate = useNavigate();

  fetchToken(setDeviceToken);

  useEffect(() => {
    let authToken = localStorage.getItem('x-auth-token');
    if (authToken && deviceToken) {
      sendTokenToServer(deviceToken);
    }
    onMessageListener()
      .then((payload) => {
        notification.open({
          type: 'info',
          duration: null,
          message: payload.notification.title,
          description: payload.notification.body,
          onClick: () => navigate('/notifications'),
        });
      })
      .catch((err) => console.log('failed: ', err));

  }, [deviceToken]);

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });


  const sendTokenToServer = async () => {
    let authToken = localStorage.getItem('x-auth-token');
    const requestData = {
      device_token: deviceToken,
    };

    try {
      const response = await axios.post(`${baseUrl}/user/device-token`, requestData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Routing />
    </div>
  );
};

export default App;
